<template>
  <div class="common-layout">
    <div class="yuming">
      {{ yuming }}
    </div>
    <br><br><el-footer>
      <el-link href="https://beian.miit.gov.cn/" target="_blank"> {{ datas }}</el-link>
    </el-footer>
  </div>
</template>

<script>
// import { isDark } from '@/composables/dark'
import { reactive, toRefs } from "vue"
export default {
  name: "App",
  setup() {
    
    const data = reactive({
      yuming:'v517.com',
      datas: '黑ICP备2021006204号-1'
      })
    return {
      ...toRefs(data),

      
    }
  }
}
</script>

<style lang="less" scoped>
.yuming{
  text-align:center;
  // padding: 10% 20%;
  font-size: 3rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  background-color: #fa1212;
  color: #dafa0b;
  font-family: "Lucida Calligraphy", cursive, serif, sans-serif;
  font-weight: bold;
}
.el-footer{
  text-align:center;
  font-size: 0.5rem;
  padding-top: 0.9rem;
  width: 100%;
height: 100%;
}
</style>
